export const PrivacyPolicy = `
<article class="policy">
    <h1>Политика конфиденциальности SlideLab</h1>
    <div class="policy__date">Вступает в силу 12 мая 2019 г.</div>
    <section>
        <h2>На что распространяется эта политика</h2>
        <p>Ваша конфиденциальность важна для нас, и поэтому мы должны быть прозрачными в
            отношении того, как мы собираем, используем и передаем информацию о вас. Эта
            политика
            призвана помочь вам понять:
        </p>
        <ul>
            <li>Какую информацию о вас мы собираем</li>
            <li>Как мы используем информацию, которую собираем</li>
            <li>Как мы делимся информацией, которую собираем</li>
            <li>Как получить доступ к вашей информации и управлять ею</li>
            <li>Как мы передаем информацию, которую мы собираем, на международном уровне</li>
            <li>Другая важная информация о конфиденциальности</li>
        </ul>
        <p>Настоящая Политика конфиденциальности распространяется на информацию,
            которую мы собирать о вас, когда вы используете наши Сервисы или иным
            образом взаимодействуете с нами (например, через наши каналы поддержки),
            если не отображается другая политика.
        </p>
        <p>Эта политика также объясняет ваш выбор того, как мы используем информацию
            о вас. Ваш выбор включает в себя то, как вы можете возражать против
            определенного использования информации о вас и как вы можете получить
            доступ и обновить определенную информацию о вас.<b>Если вы не согласны с
                этой политикой, не обращайтесь к нашим Услугам и не используйте их,
                а также аспект нашего бизнеса.</b>
        </p>
        <p>SlideLab, мы и мы ссылаемся на Procreative, LLC, которая контролирует
            информацию, которую SlideLab собирает, когда вы пользуетесь Услугами. Мы
            предлагаем ряд услуг, в том числе наши веб-продукты, настольные и
            мобильные продукты. В настоящей политике мы называем все эти продукты, а
            также другие наши службы и веб-сайты «Услугами».
        </p>
    </section>
    <section>
        <h2>Какую информацию о вас мы собираем</h2>
        <p>Мы собираем информацию о вас, когда вы предоставляете ее
            нам, когда вы используете наши Сервисы и когда другие
            источники предоставляют ее нам, как описано ниже.
        </p>
        <h3>Информация, которую вы нам предоставляете</h3>
        <p>Мы собираем информацию о вас, когда вы вводите ее
            в Сервисы или иным образом предоставляете ее нам
            напрямую.
        </p>
        <ul>
            <li><b>Учетная запись и Информация профиля:</b>
                Мы собираем информацию о вас, когда вы
                регистрируете учетную запись, создаете или
                изменяете свой профиль, устанавливаете
                предпочтения, регистрируетесь или совершаете
                покупки через Сервисы. Например, вы
                предоставляете свою контактную информацию и,
                в некоторых случаях, информацию для
                выставления счетов при регистрации в
                Сервисах. У вас также есть возможность
                добавить информацию в свой профиль, которая
                будет отображаться в наших Сервисах. Мы
                отслеживаем ваши предпочтения, когда вы
                выбираете настройки в Сервисах.
            </li>
            <li><b>Контент, который вы предоставляете через
                наши продукты:</b> Услуги включают
                используемые вами продукты SlideLab, где
                мы собираем и храним контент, который вы
                публикуете, отправляете, получаете и
                которым делитесь. Этот контент включает
                в себя любую информацию о вас, которую
                вы можете включить. Контент также
                включает файлы и ссылки, которые вы
                загружаете в Сервисы. Примеры контента,
                который мы собираем и храним, включают:
                название презентации, загруженное
                фоновое изображение или видео, любую
                информацию, которую вы вводите в
                презентацию. Мы собираем данные о
                посещениях и о том, как вы
                взаимодействуете с функциями Сервисов и
                используете их.
            </li>
            <li><b>Контент, который вы предоставляете через
                наши веб-сайты:</b> Услуги также включают
                наши веб-сайты, принадлежащие нам или
                управляемые нами. Мы собираем другой
                контент, который вы отправляете на эти
                веб-сайты, включая социальные сети или
                веб-сайты социальных сетей, управляемые
                нами. Например, вы предоставляете нам
                контент, оставляя отзыв или участвуя в
                каких-либо интерактивных функциях,
                опросах, конкурсах, рекламных акциях,
                мероприятиях или мероприятиях.
            </li>
            <li><b>Информация, которую вы предоставляете
                через наши каналы поддержки:</b> Услуги также
                включают нашу службу поддержки клиентов,
                где вы можете предоставить информацию о
                проблеме, с которой вы столкнулись при
                использовании Услуги. Независимо от
                того, назначите ли вы себя
                администратором или контактным лицом для
                выставления счетов, создадите заявку в
                службу поддержки, поговорите с одним из
                наших представителей напрямую или иным
                образом взаимодействуете с нашей службой
                поддержки, вас попросят предоставить
                контактную информацию, краткое описание
                проблемы, с которой вы столкнулись, и
                любую другую документацию, снимки экрана
                или информацию, которая может помочь в
                решении проблемы.
            </li>
            <li><b>Платежная информация.</b> Мы собираем
                определенную платежную и платежную
                информацию, когда вы регистрируетесь для
                получения определенных платных Услуг.
                Например, мы просим вас назначить
                представителя по выставлению счетов, включая
                имя и контактную информацию, при
                регистрации. Вы также можете предоставить
                платежную информацию, например данные
                платежной карты, которую мы собираем с
                помощью служб безопасной обработки платежей.
            </li>
        </ul>
    </section>
    <section>
        <h2>Информация, которую мы собираем автоматически, когда вы пользуетесь Услугами</h2>
        <p>Мы собираем
            информацию о вас, когда
            вы пользуетесь нашими
            Услугами, включая
            просмотр наших
            веб-сайтов и выполнение
            определенных действий в
            рамках Услуг.
        </p>
        <ul>
            <li><b>Использование
                вами Услуг:</b>
                Мы
                отслеживаем
                определенную
                информацию о
                вас, когда
                вы посещаете
                любую из
                наших Услуг
                и
                взаимодействуете
                с ними. Эта
                информация
                включает в
                себя
                функции,
                которые вы
                используете;
                ссылки, по
                которым вы
                переходите,
                и то, как вы
                взаимодействуете
                с другими
                пользователями
                Сервисов. Мы
                также
                собираем
                информацию о
                командах и
                людях, с
                которыми вы
                работаете, и
                о том, как
                вы с ними
                работаете,
                например, с
                кем вы
                сотрудничаете
                и общаетесь
                чаще всего.
            </li>
            <li><b>Информация об
                устройстве и
                подключении.</b>
                Мы собираем
                информацию о
                компьютере,
                который вы
                используете
                для доступа
                к Услугам.
                Эта
                информация
                об
                устройстве
                включает ваш
                тип
                подключения
                и настройки,
                когда вы
                устанавливаете,
                получаете
                доступ,
                обновляете
                или
                используете
                наши
                Сервисы. Мы
                также
                собираем
                через ваше
                устройство
                информацию о
                вашей
                операционной
                системе,
                типе
                браузера,
                IP-адресе,
                URL-адресах
                ссылающихся
                страниц
                выхода,
                идентификаторах
                устройства и
                данных о
                сбоях. Мы
                используем
                ваш IP-адрес
                и/или
                страну,
                чтобы
                приблизительно
                определить
                ваше
                местоположение,
                чтобы
                предоставить
                вам лучший
                сервис.
                Объем этой
                информации,
                которую мы
                собираем,
                зависит от
                типа и
                настроек
                устройства,
                которое вы
                используете
                для доступа
                к Услугам.
            </li>
            <li><b>Файлы cookie
                и другие
                технологии
                отслеживания.</b>
                SlideLab и
                наши
                сторонние
                партнеры,
                такие как
                наши
                партнеры по
                рекламе и
                аналитике,
                используют
                файлы cookie
                и другие
                технологии
                отслеживания
                (например,
                веб-маяки,
                идентификаторы
                устройств и
                пиксели). )
                для
                обеспечения
                функциональности
                и
                распознавания
                вас в
                различных
                Сервисах и
                на разных
                устройствах.
            </li>
        </ul>
    </section>
    <section>
        <h2>
            Информация,
            которую
            мы
            получаем
            из
            других
            источников
        </h2>
        <p>
            Мы
            получаем
            информацию
            о
            вас
            от
            других
            пользователей
            Сервиса,
            от
            сторонних
            сервисов.
        </p>
        <ul>
            <li>
                <b>Другие
                    пользователи
                    Службы:</b>
                Другие
                пользователи
                наших
                Служб
                могут
                предоставлять
                информацию
                о
                вас
                при
                отправке
                контента
                через
                Службы.
                Например,
                вы
                можете
                быть
                упомянуты
                в
                тикете
                поддержки,
                открытом
                кем-то
                другим.
                Мы
                также
                получаем
                ваш
                адрес
                электронной
                почты
                от
                других
                пользователей
                Сервиса,
                когда
                они
                предоставляют
                его,
                чтобы
                пригласить
                вас
                в
                Сервисы.
                Точно
                так
                же
                администратор
                может
                предоставить
                вашу
                контактную
                информацию,
                когда
                назначает
                вас
                ответственным
                за
                выставление
                счетов
                или
                администратором
                учетной
                записи
                вашей
                компании.
            </li>
            <li>
                <b>Другие
                    службы,
                    которые
                    вы
                    связываете
                    со
                    своей
                    учетной
                    записью:</b>
                Мы
                получаем
                информацию
                о
                вас,
                когда
                вы
                или
                ваш
                администратор
                интегрируете
                или
                связываете
                стороннюю
                службу
                с
                нашими
                Службами.
                Например,
                если
                вы
                создаете
                учетную
                запись
                или
                входите
                в
                Службы,
                используя
                свои
                учетные
                данные
                Google,
                мы
                получаем
                ваше
                имя
                и
                адрес
                электронной
                почты
                в
                соответствии
                с
                настройками
                вашего
                профиля
                Google,
                чтобы
                аутентифицировать
                вас.
                Вы
                или
                ваш
                администратор
                также
                можете
                интегрировать
                наши
                Услуги
                с
                другими
                услугами,
                которыми
                вы
                пользуетесь.
                Информация,
                которую
                мы
                получаем,
                когда
                вы
                связываете
                или
                интегрируете
                наши
                Услуги
                со
                сторонней
                службой,
                зависит
                от
                настроек,
                разрешений
                и
                политики
                конфиденциальности,
                контролируемых
                этой
                сторонней
                службой.
                Вам
                всегда
                следует
                проверять
                настройки
                конфиденциальности
                и
                уведомления
                в
                этих
                сторонних
                службах,
                чтобы
                понять,
                какие
                данные
                могут
                быть
                раскрыты
                нам
                или
                переданы
                нашим
                Службам.
            </li>
        </ul>
    </section>
    <section>
        <h2>
            Как
            мы
            используем
            информацию,
            которую
            мы
            собираем
        </h2>
        <p>
            Ниже
            приведены
            конкретные
            цели,
            для
            которых
            мы
            используем
            информацию
            о
            вас,
            которую
            мы
            собираем.
        </p>
        <ul>
            <li>
                <b>Для
                    предоставления
                    Услуг
                    и
                    персонализировать
                    ваш
                    опыт:</b>
                Мы
                используем
                информацию
                о
                вас
                для
                предоставления
                вам
                Услуг,
                в
                том
                числе
                для
                обработки
                транзакций
                с
                вами,
                вашей
                аутентификации
                при
                входе
                в
                систему,
                обеспечения
                поддержки
                клиентов,
                а
                также
                управления
                и
                обслуживания
                Услуг.
                Например,
                мы
                используем
                имя
                и
                изображение,
                которые
                вы
                предоставляете
                в
                своей
                учетной
                записи,
                чтобы
                идентифицировать
                вас
                для
                других
                пользователей
                Сервиса.
                Наши
                Услуги
                также
                включают
                специальные
                функции,
                которые
                персонализируют
                ваш
                опыт,
                повышают
                вашу
                производительность
                и
                улучшают
                вашу
                способность
                эффективно
                сотрудничать
                с
                другими,
                автоматически
                анализируя
                действия
                вашей
                команды,
                чтобы
                предоставлять
                каналы
                активности
                и
                уведомления,
                которые
                важны
                для
                вас
                и
                вашей
                команды.
                Мы
                можем
                использовать
                ваш
                домен
                электронной
                почты,
                чтобы
                сделать
                вывод
                о
                вашей
                принадлежности
                к
                определенной
                организации
                или
                отрасли,
                чтобы
                персонализировать
                контент
                и
                возможности,
                которые
                вы
                получаете
                на
                наших
                веб-сайтах.
                Если
                вы
                используете
                несколько
                Сервисов,
                мы
                объединяем
                информацию
                о
                вас
                и
                ваших
                действиях,
                чтобы
                обеспечить
                интегрированный
                опыт,
                например,
                чтобы
                вы
                могли
                находить
                информацию
                из
                одного
                Сервиса
                во
                время
                поиска
                в
                другом
                или
                представлять
                релевантную
                информацию
                о
                продукте,
                когда
                вы
                путешествуете
                по
                нашим
                веб-сайтам.
            </li>
            <li>
                <b>Для
                    исследований
                    и
                    разработок:</b>
                Мы
                всегда
                ищем
                способы
                сделать
                наши
                Сервисы
                более
                интеллектуальными,
                быстрыми,
                безопасными,
                интегрированными
                и
                полезными
                для
                вас.
                Мы
                используем
                коллективные
                знания
                о
                том,
                как
                люди
                используют
                наши
                Сервисы,
                и
                отзывы,
                предоставленные
                непосредственно
                нам,
                для
                устранения
                неполадок
                и
                выявления
                тенденций,
                использования,
                моделей
                действий
                и
                областей
                для
                интеграции
                и
                улучшения
                Сервисов.
                Мы
                также
                тестируем
                и
                анализируем
                некоторые
                новые
                функции
                с
                некоторыми
                пользователями,
                прежде
                чем
                внедрять
                эту
                функцию
                для
                всех
                пользователей.
            </li>
            <li>
                <b>Для
                    связи
                    с
                    вами
                    по
                    поводу
                    Услуг:</b>
                Мы
                используем
                вашу
                контактную
                информацию
                для
                отправки
                сообщений
                о
                транзакциях
                по
                электронной
                почте
                и
                в
                рамках
                Услуг,
                включая
                подтверждение
                ваших
                покупок,
                напоминание
                об
                истечении
                срока
                подписки,
                ответы
                на
                ваши
                комментарии,
                вопросы
                и
                запросы,
                обеспечение
                поддержки
                клиентов
                и
                отправка
                вам
                технических
                уведомлений,
                обновлений,
                предупреждений
                системы
                безопасности
                и
                административных
                сообщений.
                Мы
                также
                отправляем
                вам
                сообщения,
                когда
                вы
                подключаетесь
                к
                определенной
                Службе,
                чтобы
                помочь
                вам
                стать
                более
                опытным
                в
                использовании
                этой
                Службы.
                Эти
                сообщения
                являются
                частью
                Услуг,
                и
                в
                большинстве
                случаев
                вы
                не
                можете
                отказаться
                от
                них.
                Если
                доступен
                отказ,
                вы
                найдете
                эту
                опцию
                в
                самом
                сообщении
                или
                в
                настройках
                вашей
                учетной
                записи.
            </li>
            <li>
                <b>Для
                    маркетинга,
                    продвижения
                    и
                    стимулирования
                    взаимодействия
                    с
                    Услугами:</b>
                Мы
                используем
                вашу
                контактную
                информацию
                и
                информацию
                о
                том,
                как
                вы
                используете
                Услуги,
                для
                отправки
                рекламных
                сообщений,
                которые
                могут
                представлять
                для
                вас
                особый
                интерес,
                в
                том
                числе
                по
                электронной
                почте
                и
                показывая
                рекламу
                SlideLab
                на
                веб-сайтах
                и
                в
                приложениях
                других
                компаний,
                а
                также
                на
                таких
                платформах,
                как
                Facebook
                и
                Google.
                Эти
                сообщения
                направлены
                на
                стимулирование
                взаимодействия
                и
                максимальное
                увеличение
                того,
                что
                вы
                получаете
                от
                Услуг,
                включая
                информацию
                о
                новых
                функциях,
                запросы
                на
                участие
                в
                опросах,
                информационные
                бюллетени
                и
                события,
                которые,
                по
                нашему
                мнению,
                могут
                вас
                заинтересовать.
                Мы
                также
                сообщаем
                вам
                о
                новых
                предложениях
                продуктов,
                рекламных
                акциях
                и
                конкурсах.
                Вы
                можете
                контролировать
                получение
                таких
                сообщений,
                как
                описано
                ниже
                в
                разделе
                «Отказ
                от
                сообщений».
            </li>
            <li>
                <b>Для
                    поддержки
                    клиентов:</b>
                Мы
                используем
                вашу
                информацию
                для
                решения
                технических
                проблем,
                с
                которыми
                вы
                сталкиваетесь,
                для
                ответа
                на
                ваши
                запросы
                о
                помощи,
                для
                анализа
                информации
                о
                сбоях,
                а
                также
                для
                исправления
                и
                улучшения
                Услуг.
            </li>
            <li>
                <b>Для
                    безопасности:</b>
                Мы
                используем
                информацию
                о
                вас
                и
                использовании
                вами
                Сервиса
                для
                проверки
                учетных
                записей
                и
                действий,
                отслеживания
                подозрительных
                или
                мошеннических
                действий
                и
                выявления
                нарушений
                политик
                Сервиса.
            </li>
            <li>
                <b>Для
                    защиты
                    наших
                    законных
                    деловых
                    интересов
                    и
                    законных
                    прав:</b>
                Если
                это
                требуется
                по
                закону
                или
                когда
                мы
                считаем
                необходимым
                защитить
                наши
                законные
                права,
                интересы
                и
                интересы
                других
                лиц,
                мы
                используем
                информацию
                о
                вам
                в
                связи
                с
                судебными
                претензиями,
                комплаенс,
                регулирующими
                и
                аудиторскими
                функциями,
                а
                также
                раскрытием
                информации
                в
                связи
                с
                приобретением,
                слиянием
                или
                продажей
                бизнеса.
            </li>
            <li>
                <b>С
                    вашего
                    согласия:</b>
                Мы
                используем
                информацию
                о
                вас,
                если
                вы
                дали
                нам
                на
                это
                согласие,
                для
                конкретной
                цели,
                не
                указанной
                выше.
                Например,
                с
                вашего
                разрешения
                мы
                можем
                публиковать
                отзывы
                или
                избранные
                истории
                клиентов
                для
                продвижения
                Услуг.
            </li>
            <li>
                <b>Правовые
                    основания
                    для
                    обработки
                    (для
                    пользователей
                    EEA):</b>
                Если
                вы
                являетесь
                физическим
                лицом
                в
                Европейской
                экономической
                зоне
                (ЕЭЗ),
                мы
                собираем
                и
                обрабатываем
                информацию
                о
                вас
                только
                в
                тех
                случаях,
                когда
                у
                нас
                есть
                правовые
                основания
                для
                делать
                это
                в
                соответствии
                с
                действующим
                законодательством
                ЕС.
                Правовые
                основания
                зависят
                от
                используемых
                вами
                Услуг
                и
                от
                того,
                как
                вы
                их
                используете.
                Это
                означает,
                что
                мы
                собираем
                и
                используем
                вашу
                информацию
                только
                в
                следующих
                случаях:
                <ul>

                    <li>
                        она
                        нужна
                        нам
                        для
                        предоставления
                        вам
                        Услуг,
                        в
                        том
                        числе
                        для
                        работы
                        Услуг,
                        обеспечения
                        поддержки
                        клиентов
                        и
                        персонализированных
                        функций,
                        а
                        также
                        для
                        защиты
                        безопасности
                        и
                        защиты
                        Услуг;
                    </li>
                    <li>
                        Он
                        отвечает
                        законным
                        интересам
                        (которые
                        не
                        перекрываются
                        вашими
                        интересами
                        в
                        области
                        защиты
                        данных),
                        например,
                        для
                        исследований
                        и
                        разработок,
                        для
                        продвижения
                        на
                        рынок
                        и
                        продвижения
                        Услуг
                        и
                        для
                        защиты
                        наших
                        законных
                        прав
                        и
                        интересов;
                    </li>
                    <li>
                        Вы
                        даете
                        нам
                        согласие
                        на
                        это
                        для
                        определенной
                        цели;
                        или
                    </li>
                    <li>
                        Нам
                        необходимо
                        обработать
                        ваши
                        данные,
                        чтобы
                        выполнить
                        юридическое
                        обязательство.
                    </li>
                </ul>
            </li>
            <p>
                Если
                вы
                дали
                согласие
                на
                использование
                нами
                информации
                о
                вас
                для
                определенной
                цели,
                вы
                имеете
                право
                передумать
                в
                любое
                время,
                но
                это
                не
                повлияет
                на
                уже
                выполненную
                обработку.
                Если
                мы
                используем
                вашу
                информацию,
                потому
                что
                мы
                или
                третья
                сторона
                (например,
                ваш
                работодатель)
                имеем
                в
                этом
                законный
                интерес,
                вы
                имеете
                право
                возражать
                против
                такого
                использования,
                хотя
                в
                некоторых
                случаях
                это
                может
                означать
                прекращение
                использования
                Услуг.
            </p>
        </ul>
    </section>
    <section>
        <h2>Как мы делимся собираемой информацией</h2>
        <p>
            Мы не занимаемся продажей информации о вас рекламодателям или другим третьим лицам. Мы передаем информацию,
            которую мы собираем о вас, описанными ниже способами.
        </p>
        <h3>Передача информации другим пользователям Службы</h3>
        <p>Когда вы пользуетесь Службами, мы делимся определенной информацией о вас с другими пользователями Службы.</p>
        <ul>
            <li>
                <b>Для
                    совместной
                    работы:</b>
                Вы
                можете
                создавать
                контент,
                который
                может
                содержать
                информацию
                о
                вас,
                и
                предоставлять
                другим
                пользователям
                разрешение
                на
                просмотр,
                совместное
                использование,
                редактирование,
                копирование
                и
                загрузку
                этого
                контента
                в
                соответствии
                с
                вашими
                или
                вашими
                настройками.
                администратор
                (если
                применимо)
                выберите.
            </li>
            <li>
                <b>Управляемые
                    учетные
                    записи
                    и
                    администраторы:</b>
                Если
                вы
                регистрируетесь
                или
                получаете
                доступ
                к
                Услугам,
                используя
                адрес
                электронной
                почты
                с
                доменом,
                который
                принадлежит
                вашему
                работодателю
                или
                организации,
                и
                такая
                организация
                желает
                создать
                учетную
                запись
                или
                сайт
                ,
                определенная
                информация
                о
                вас,
                включая
                ваше
                имя,
                изображение
                профиля,
                контактную
                информацию,
                контент
                и
                прошлое
                использование
                вашей
                учетной
                записи,
                может
                стать
                доступной
                для
                администратора
                этой
                организации
                и
                других
                пользователей
                Службы,
                использующих
                тот
                же
                домен.
                Если
                вы
                являетесь
                администратором
                определенного
                сайта
                или
                группы
                пользователей
                в
                Сервисах,
                мы
                можем
                передавать
                вашу
                контактную
                информацию
                нынешним
                или
                бывшим
                пользователям
                Сервиса
                с
                целью
                облегчения
                запросов,
                связанных
                с
                Сервисом.
            </li>
            <li>
                <b>Форумы
                    сообщества.</b>
                Наши
                веб-сайты
                предлагают
                общедоступные
                блоги,
                форумы,
                средства
                отслеживания
                проблем
                и
                вики-сайты,
                такие
                как
                сообщество
                SlideLab.
                Вы
                должны
                знать,
                что
                любая
                информация,
                которую
                вы
                предоставляете
                на
                этих
                веб-сайтах,
                включая
                информацию
                профиля,
                связанную
                с
                учетной
                записью,
                которую
                вы
                используете
                для
                публикации
                информации,
                может
                быть
                прочитана,
                собрана
                и
                использована
                любым
                представителем
                общественности,
                который
                получает
                доступ
                к
                этим
                веб-сайтам.
                Ваши
                сообщения
                и
                определенная
                информация
                профиля
                могут
                остаться
                даже
                после
                того,
                как
                вы
                удалите
                свою
                учетную
                запись.
                Мы
                призываем
                вас
                учитывать
                конфиденциальность
                любой
                информации,
                которую
                вы
                вводите
                в
                эти
                Услуги.
                Чтобы
                запросить
                удаление
                вашей
                информации
                с
                общедоступных
                веб-сайтов,
                управляемых
                нами,
                свяжитесь
                с
                нами,
                как
                указано
                ниже.
                В
                некоторых
                случаях
                мы
                не
                сможем
                удалить
                вашу
                информацию,
                и
                в
                этом
                случае
                мы
                сообщим
                вам,
                если
                не
                сможем
                и
                почему.
            </li>
        </ul>
        <h3>
            Обмен
            информацией
            с
            третьими
            сторонами
        </h3>
        <p>
            Мы
            делимся
            информацией
            с
            третьими
            сторонами,
            которые
            помогают
            нам
            работать,
            предоставлять,
            улучшать,
            интегрировать,
            настраивать,
            поддерживать
            и
            продавать
            наши
            Услуги.
        </p>
        <ul>
            <li>
                <b>Поставщики
                    услуг:</b>
                Мы
                работаем
                со
                сторонними
                поставщиками
                услуг,
                которые
                предоставляют
                нам
                разработку
                веб-сайтов
                и
                приложений,
                хостинг,
                техническое
                обслуживание,
                резервное
                копирование,
                хранение,
                виртуальную
                инфраструктуру,
                обработку
                платежей,
                анализ
                и
                другие
                услуги,
                которые
                могут
                требовать
                от
                них
                доступа
                или
                использования
                информации
                о
                вас.
                Если
                поставщику
                услуг
                необходимо
                получить
                доступ
                к
                информации
                о
                вас
                для
                оказания
                услуг
                от
                нашего
                имени,
                он
                делает
                это
                в
                соответствии
                с
                нашими
                строгими
                инструкциями,
                включая
                политики
                и
                процедуры,
                разработанные
                для
                защиты
                вашей
                информации.
            </li>
            <li>
                <b>Сторонние
                    приложения:</b>
                Вы,
                ваш
                администратор
                или
                другие
                пользователи
                Сервисов
                можете
                добавить
                новые
                функции
                или
                изменить
                поведение
                Сервисов,
                включив
                интеграцию
                со
                сторонними
                приложениями
                в
                Сервисах.
                Это
                может
                предоставить
                сторонним
                приложениям
                доступ
                к
                вашей
                учетной
                записи
                и
                информации
                о
                вас,
                такой
                как
                ваше
                имя
                и
                адрес
                электронной
                почты,
                а
                также
                любому
                контенту,
                который
                вы
                решите
                использовать
                в
                связи
                с
                этими
                приложениями.<br>
                Политики
                и
                процедуры
                сторонних
                приложений
                не
                контролируются
                нами,
                и
                эта
                политика
                конфиденциальности
                не
                распространяется
                на
                то,
                как
                сторонние
                приложения
                используют
                вашу
                информацию.
                Мы
                рекомендуем
                вам
                ознакомиться
                с
                политиками
                конфиденциальности
                третьих
                сторон,
                прежде
                чем
                подключаться
                к
                их
                приложениям
                или
                службам
                или
                использовать
                их,
                чтобы
                узнать
                больше
                об
                их
                методах
                конфиденциальности
                и
                обработки
                информации.
                Если
                вы
                возражаете
                против
                того,
                чтобы
                информация
                о
                вас
                была
                передана
                этим
                третьим
                лицам,
                удалите
                приложение.
            </li>
            <li>
                <b>Ссылки на сторонние сайты.</b>
                Услуги могут включать ссылки, которые направят вас на другие веб-сайты или службы, методы обеспечения
                конфиденциальности которых могут отличаться от наших. Если вы отправляете информацию на любой из этих
                сторонних сайтов, ваша информация регулируется их политикой конфиденциальности, а не этой. Мы
                рекомендуем вам внимательно ознакомиться с политикой конфиденциальности любого веб-сайта, который вы
                посещаете.
            </li>
            <li>
                <b>Сторонние виджеты.</b>
                Некоторые из наших Сервисов содержат виджеты и функции социальных сетей, например кнопку «Твитнуть» в
                Твиттере. Эти виджеты и функции собирают ваш IP-адрес, страницу, которую вы посещаете в Сервисах, и
                могут устанавливать файлы cookie, чтобы функция работала должным образом. Виджеты и функции социальных
                сетей либо размещаются третьей стороной, либо размещаются непосредственно в наших Сервисах. Ваше
                взаимодействие с этими функциями регулируется политикой конфиденциальности компании, предоставляющей их.
            </li>
            <li>
                <b>Соблюдение
                    требований
                    правоприменения
                    и
                    применимых
                    законов;
                    Защита
                    наших
                    прав:</b>
                В
                исключительных
                случаях
                мы
                можем
                передавать
                информацию
                о
                вас
                третьей
                стороне,
                если
                мы
                считаем,
                что
                передача
                разумно
                необходима
                для
                (а)
                соблюдения
                любого
                применимого
                закона,
                постановления,
                судебного
                процесса
                или
                запроса
                правительства,
                в
                том
                числе
                для
                соответствовать
                требованиям
                национальной
                безопасности,
                (b)
                обеспечивать
                соблюдение
                наших
                соглашений,
                политик
                и
                условий
                обслуживания,
                (c)
                защищать
                безопасность
                или
                целостность
                наших
                продуктов
                и
                услуг,
                (d)
                защищать
                SlideLab,
                наших
                клиентов
                или
                общественность
                от
                вреда
                или
                незаконной
                деятельности,
                или
                (e)
                реагировать
                на
                чрезвычайную
                ситуацию,
                которая,
                по
                нашему
                добросовестному
                мнению,
                требует
                от
                нас
                раскрытия
                информации,
                чтобы
                помочь
                предотвратить
                смерть
                или
                серьезные
                телесные
                повреждения
                любого
                человека.
            </li>
        </ul>
    </section>
    <section>
        <h2>
            Как мы храним и защищаем собираемую информацию
        </h2>
        <h3>Хранение и безопасность информации</h3>
        <p>
            Мы пользуемся услугами поставщиков услуг хостинга данных в США и размещаем собираемую информацию, а также
            используем технические меры для защиты ваших данных.
        </p>
        <br>Несмотря на то, что мы реализуем меры безопасности, предназначенные для защиты вашей информации, ни одна
        система безопасности не является непроницаемой, и из-за присущей Интернету природы мы не можем гарантировать,
        что данные во время передачи через Интернет или пока они хранятся в наших системах или иным образом находятся
        под нашим контролем, они абсолютно защищены от вторжения посторонних.
    </section>
    <section>
        <h3>
            Как долго мы храним информацию
        </h3>
        <p>
            Как
            долго
            мы
            храним
            информацию
            о
            вас,
            которую
            мы
            собираем,
            зависит
            от
            типа
            информации,
            как
            подробно
            описано
            ниже.
            По
            истечении
            этого
            времени
            мы
            либо
            удалим,
            либо
            анонимизируем
            вашу
            информацию.
        </p>
        <ul>
            <li>
                <b>Информация
                    об
                    учетной
                    записи:</b>
                Мы
                сохраняем
                информацию
                о
                вашей
                учетной
                записи
                до
                тех
                пор,
                пока
                вы
                не
                удалите
                ее.
                Мы
                также
                сохраняем
                часть
                вашей
                информации
                по
                мере
                необходимости
                для
                выполнения
                наших
                юридических
                обязательств,
                разрешения
                споров,
                обеспечения
                соблюдения
                наших
                соглашений,
                поддержки
                деловых
                операций
                и
                дальнейшего
                развития
                и
                улучшения
                наших
                Услуг.
            </li>
            <li>
                <b>Информация,
                    которой
                    вы
                    делитесь
                    в
                    Службах:</b>
                Если
                ваша
                учетная
                запись
                будет
                удалена,
                часть
                вашей
                информации
                и
                контента,
                которые
                вы
                предоставили,
                останутся,
                чтобы
                члены
                вашей
                команды
                или
                другие
                пользователи
                могли
                в
                полной
                мере
                использование
                Услуг.
            </li>
            <li>
                <b>Управляемые
                    учетные
                    записи:</b>
                Если
                Услуги
                предоставляются
                вам
                через
                организацию
                (например,
                вашего
                работодателя),
                мы
                храним
                вашу
                информацию
                до
                тех
                пор,
                пока
                этого
                требует
                администратор
                вашей
                учетной
                записи.
            </li>
            <li>
                <b>Маркетинговая информация.</b>
                Если
                вы
                решили
                получать
                от
                нас
                маркетинговые
                сообщения
                по
                электронной
                почте,
                мы
                сохраняем
                информацию
                о
                ваших
                маркетинговых
                предпочтениях,
                если
                вы
                специально
                не
                попросите
                нас
                удалить
                такую
                информацию.
                Мы
                храним
                информацию,
                полученную
                с
                помощью
                файлов
                cookie
                и
                других
                технологий
                отслеживания,
                в
                течение
                разумного
                периода
                времени
                с
                даты
                создания
                такой
                информации.
            </li>
        </ul>
    </section>
    <section>
        <h2>
            Как
            получить
            доступ
            к
            вашей
            информации
            и
            управлять
            ею
        </h2>
        <p>
            У
            вас
            есть
            определенные
            возможности
            выбора,
            когда
            речь
            идет
            о
            вашей
            информации.
            Ниже
            приводится
            краткое
            описание
            этих
            вариантов,
            способов
            их
            использования
            и
            любых
            ограничений.
            Мы
            ответим
            на
            запросы
            об
            этом
            в
            разумные
            сроки.
        </p>
        <h3>
            Ваш
            выбор:
        </h3>
        <p>
            Вы
            имеете
            право
            запросить
            копию
            вашей
            информации,
            возразить
            против
            использования
            нами
            вашей
            информации
            (в
            том
            числе
            для
            маркетинговых
            целях),
            запросить
            удаление
            или
            ограничение
            вашей
            информации
            или
            запросить
            вашу
            информацию
            в
            структурированном
            электронном
            формате.
            Ниже
            мы
            описываем
            инструменты
            и
            процессы
            для
            создания
            таких
            запросов.
            Вы
            можете
            связаться
            с
            нами,
            как
            указано
            в
            разделе
            "Контакты"
            ниже,
            чтобы
            запросить
            помощь.
        </p>
        <br>Ваш
        запрос
        и
        выбор
        могут
        быть
        ограничены
        в
        определенных
        случаях:
        например,
        если
        выполнение
        вашего
        запроса
        приведет
        к
        раскрытию
        информации
        о
        другом
        человеке
        или
        если
        вы
        попросите
        удалить
        информацию,
        которую
        нам
        или
        вашему
        администратору
        разрешено
        законом
        или
        у
        которой
        есть
        веские
        законные
        интересы.
        Если
        вы
        попросили
        нас
        поделиться
        данными
        с
        третьими
        лицами,
        например,
        путем
        установки
        сторонних
        приложений,
        вам
        нужно
        будет
        напрямую
        связаться
        с
        этими
        сторонними
        поставщиками
        услуг,
        чтобы
        удалить
        вашу
        информацию
        или
        иным
        образом
        ограничить
        ее.
        Если
        у
        вас
        есть
        нерешенные
        проблемы,
        вы
        можете
        иметь
        право
        подать
        жалобу
        в
        орган
        по
        защите
        данных
        в
        стране,
        где
        вы
        живете,
        работаете
        или
        где,
        по
        вашему
        мнению,
        ваши
        права
        были
        нарушены.
        <ul>
            <li>
                <b>Доступ
                    к
                    вашей
                    информации
                    и
                    ее
                    обновление.</b>
                Наши
                Услуги
                и
                связанная
                с
                ними
                документация
                дают
                вам
                возможность
                получать
                доступ
                к
                определенной
                информации
                о
                вас
                и
                обновлять
                ее
                в
                рамках
                Услуги.
                Вы
                можете
                обновить
                информацию
                своего
                профиля
                в
                настройках
                вашего
                профиля.
            </li>
            <li>
                <b>Удалите
                    свою
                    учетную
                    запись.</b>
                Если
                вы
                больше
                не
                хотите
                пользоваться
                нашими
                Службами,
                вы
                или
                ваш
                администратор
                можете
                удалить
                свою
                учетную
                запись
                Служб.
                Если
                вы
                можете
                удалить
                свою
                учетную
                запись,
                эта
                настройка
                доступна
                вам
                в
                настройках
                вашей
                учетной
                записи.
                В
                противном
                случае
                обратитесь
                к
                администратору.
                Вы
                также
                можете
                обратиться
                в
                службу
                поддержки
                SlideLab
                (info@slide-lab.ru),
                чтобы
                запросить
                удаление
                вашей
                учетной
                записи.
            </li>
            <li>
                <b>Удалить
                    вашу
                    информацию:</b>
                Наши
                Сервисы
                дают
                вам
                возможность
                удалить
                определенную
                информацию
                о
                вас
                из
                Сервиса.
                Например,
                вы
                можете
                удалить
                определенную
                информацию
                профиля
                в
                настройках
                вашего
                профиля.
                Обратите
                внимание,
                однако,
                что
                нам
                может
                потребоваться
                сохранить
                определенную
                информацию
                для
                целей
                ведения
                учета,
                для
                завершения
                транзакций
                или
                для
                выполнения
                наших
                юридических
                обязательств.
            </li>
            <li>
                <b>Запросить,
                    чтобы
                    мы
                    прекратили
                    использовать
                    вашу
                    информацию:</b>
                В
                некоторых
                случаях
                вы
                можете
                попросить
                нас
                прекратить
                доступ,
                хранение,
                использование
                и
                иную
                обработку
                вашей
                информации,
                если
                вы
                считаете,
                что
                у
                нас
                нет
                соответствующих
                права
                на
                это.
                Например,
                если
                вы
                считаете,
                что
                учетная
                запись
                Сервисов
                была
                создана
                для
                вас
                без
                вашего
                разрешения
                или
                вы
                больше
                не
                являетесь
                активным
                пользователем,
                вы
                можете
                запросить
                удаление
                вашей
                учетной
                записи
                в
                соответствии
                с
                настоящей
                политикой.
                Если
                вы
                дали
                нам
                согласие
                на
                использование
                вашей
                информации
                в
                ограниченных
                целях,
                вы
                можете
                связаться
                с
                нами,
                чтобы
                отозвать
                это
                согласие,
                но
                это
                не
                повлияет
                на
                какую-либо
                обработку,
                которая
                уже
                имела
                место
                в
                то
                время.
                Вы
                также
                можете
                отказаться
                от
                использования
                нами
                вашей
                информации
                в
                маркетинговых
                целях,
                связавшись
                с
                нами,
                как
                указано
                ниже.
                Когда
                вы
                делаете
                такие
                запросы,
                нам
                может
                потребоваться
                время
                для
                расследования
                и
                облегчения
                вашего
                запроса.
                В
                случае
                задержки
                или
                спора
                относительно
                того,
                имеем
                ли
                мы
                право
                продолжать
                использовать
                вашу
                информацию,
                мы
                ограничим
                любое
                дальнейшее
                использование
                вашей
                информации
                до
                тех
                пор,
                пока
                запрос
                не
                будет
                удовлетворен
                или
                спор
                не
                будет
                разрешен,
                при
                условии,
                что
                ваш
                администратор
                не
                возражает
                (если
                применимо).
                Если
                вы
                возражаете
                против
                того,
                чтобы
                информация
                о
                вас
                была
                передана
                в
                стороннюю
                интеграцию,
                отключите
                интеграцию
                или
                обратитесь
                для
                этого
                к
                своему
                администратору.
            </li>
            <li>
                <b>Отказ
                    от
                    получения
                    сообщений:</b>
                Вы
                можете
                отказаться
                от
                получения
                рекламных
                сообщений
                от
                нас,
                используя
                ссылку
                для
                отказа
                от
                подписки
                в
                каждом
                письме
                или
                связавшись
                с
                нами,
                как
                указано
                ниже,
                чтобы
                удалить
                вашу
                контактную
                информацию
                из
                наш
                рекламный
                список
                адресов
                электронной
                почты
                или
                регистрационная
                база
                данных.
                Даже
                после
                того,
                как
                вы
                откажетесь
                от
                получения
                рекламных
                сообщений
                от
                нас,
                вы
                продолжите
                получать
                от
                нас
                сообщения
                о
                транзакциях,
                касающихся
                наших
                Услуг.
            </li>
            <li>
                <b>Переносимость
                    данных.</b>
                Переносимость
                данных –
                это
                возможность
                получать
                часть
                вашей
                информации
                в
                формате,
                который
                вы
                можете
                передавать
                от
                одного
                поставщика
                услуг
                к
                другому
                (например,
                при
                переносе
                номера
                мобильного
                телефона
                в
                другой
                перевозчик).
                В
                зависимости
                от
                контекста
                это
                относится
                к
                некоторой
                части
                вашей
                информации,
                но
                не
                ко
                всей
                вашей
                информации.
                По
                вашему
                запросу
                мы
                предоставим
                вам
                электронный
                файл
                с
                основной
                информацией
                об
                учетной
                записи
                и
                информацией,
                которую
                вы
                создаете
                в
                пространствах,
                находящихся
                под
                вашим
                исключительным
                контролем,
                таких
                как
                ваша
                личная
                учетная
                запись
                SlideLab.
            </li>
        </ul>
    </section>
    <section>
        <h2>
            Как
            мы
            передаем
            информацию,
            которую
            собираем,
            на
            международном
            уровне
        </h2>
        <h3>
            Международная
            передача
            информации,
            которую
            мы
            собираем
        </h3>
        <p>
            Мы
            собираем
            информацию
            по
            всему
            миру
            и
            в
            основном
            храним
            эту
            информацию
            в
            Соединенных
            Штатах.
            Мы
            передаем,
            обрабатываем
            и
            храним
            вашу
            информацию
            за
            пределами
            страны
            вашего
            проживания,
            везде,
            где
            мы
            или
            наши
            сторонние
            поставщики
            услуг
            работаем
            с
            целью
            предоставления
            вам
            Услуг.
            Всякий
            раз,
            когда
            мы
            передаем
            вашу
            информацию,
            мы
            предпринимаем
            шаги
            для
            ее
            защиты.
        </p>
        <ul>
            <li>
                <b>Международная
                    передача
                    внутри
                    компаний
                    SlideLab:</b>
                Чтобы
                облегчить
                наши
                глобальные
                операции,
                мы
                передаем
                информацию
                в
                Соединенные
                Штаты
                и
                разрешаем
                доступ
                к
                этой
                информации
                из
                стран,
                в
                которых
                SlideLab
                осуществляет
                свою
                деятельность
                в
                целях,
                описанных
                в
                настоящей
                политике.
                Законы
                о
                конфиденциальности
                и
                защите
                данных
                в
                этих
                странах
                могут
                не
                соответствовать
                законам
                многих
                стран,
                где
                находятся
                наши
                клиенты
                и
                пользователи.
            </li>
            <li>
                <b>Международная
                    передача
                    третьим
                    лицам:</b>
                Некоторые
                из
                третьих
                лиц,
                описанных
                в
                настоящей
                политике
                конфиденциальности,
                которые
                предоставляют
                нам
                услуги
                по
                контракту,
                находятся
                в
                других
                странах,
                которые
                могут
                не
                иметь
                эквивалентной
                конфиденциальности
                и
                данных.
                законы
                о
                защите
                страны,
                в
                которой
                вы
                проживаете.
            </li>
        </ul>
    </section>
    <section>
        <h2>
            Другая
            важная
            информация
            о
            конфиденциальности
        </h2>
        <h3>
            Уведомление
            для
            конечных
            пользователей
        </h3>
        <p>
            Наши
            Сервисы
            предназначены
            как
            для
            личного
            использования,
            так
            и
            для
            использования
            организациями.
            Если
            Услуги
            предоставляются
            вам
            через
            организацию
            (например,
            вашего
            работодателя),
            эта
            организация
            является
            администратором
            Услуг
            и
            несет
            ответственность
            за
            учетные
            записи
            и/или
            сайты
            Услуг,
            которые
            она
            контролирует.
            В
            этом
            случае
            обращайтесь
            с
            вопросами
            о
            конфиденциальности
            данных
            к
            администратору,
            поскольку
            использование
            вами
            Услуг
            регулируется
            политиками
            этой
            организации.
            Мы
            не
            несем
            ответственности
            за
            политику
            конфиденциальности
            или
            безопасности
            организации
            администратора,
            которая
            может
            отличаться
            от
            этой
            политики.
        </p>
        <br>Администраторы
        могут:
        <ul>
            <li>
                требовать
                от
                вас
                сброса
                пароля
                учетной
                записи;
            </li>
            <li>
                ограничивать,
                приостанавливать
                или
                прекращать
                ваш
                доступ
                к
                Услугам
                или
                вашей
                учетной
                записи;
            </li>
            <li>
                контролировать
                вашу
                способность
                редактировать,
                ограничивать,
                изменять
                или
                удалять
                информацию
                учетной
                записи;
            </li>
            <li>
                изменять
                информацию
                вашей
                учетной
                записи,
                включая
                информация
                профиля
                или
                адрес
                электронной
                почты,
                связанный
                с
                вашей
                учетной
                записью;
            </li>
            <li>
                доступ
                к
                информации
                в
                вашей
                учетной
                записи
                и
                о
                ней;
            </li>
            <li>
                доступ
                к
                информации,
                хранящейся
                как
                часть
                вашей
                учетной
                записи,
                или
                ее
                сохранение;
            </li>
            <li>
                установка
                или
                удаление
                сторонних
                интеграций
            </li>
        </ul>
        <p>
            Если
            вы
            не
            хотите,
            чтобы
            администратор
            мог
            установить
            контроль
            над
            вашей
            учетной
            записью
            или
            использованием
            Сервисов,
            используйте
            свой
            личный
            адрес
            электронной
            почты
            для
            регистрации
            или
            доступа
            к
            Сервисам.
            Если
            администратор
            еще
            не
            установил
            контроль
            над
            вашей
            учетной
            записью
            или
            доступом
            к
            Услугам,
            вы
            можете
            обновить
            адрес
            электронной
            почты,
            связанный
            с
            вашей
            учетной
            записью,
            в
            настройках
            учетной
            записи
            в
            своем
            профиле.
            Как
            только
            администратор
            установит
            контроль
            над
            вашей
            учетной
            записью
            или
            использованием
            Служб,
            вы
            больше
            не
            сможете
            изменить
            адрес
            электронной
            почты,
            связанный
            с
            вашей
            учетной
            записью,
            без
            одобрения
            администратора.
        </p>
        <br>Пожалуйста,
        свяжитесь
        с
        вашей
        организацией
        или
        ознакомьтесь
        с
        организационными
        политиками
        вашего
        администратора.
        для
        получения
        дополнительной
        информации.
        <h3>
            Наша
            политика
            в
            отношении
            детей
        </h3>
        <p>
            Услуги
            не
            предназначены
            для
            лиц
            младше
            16
            лет.
            Мы
            не
            собираем
            преднамеренно
            личную
            информацию
            от
            детей
            младше
            16
            лет.
            Если
            нам
            станет
            известно,
            что
            ребенок
            младше
            16
            лет
            предоставил
            нам
            личную
            информацию,
            мы
            предпримем
            шаги
            для
            удаления
            такой
            информации.
            Если
            вам
            стало
            известно,
            что
            ребенок
            предоставил
            нам
            личную
            информацию,
            обратитесь
            в
            нашу
            службу
            поддержки.
        </p>
        <h3>
            Изменения
            в
            нашей
            политике
            конфиденциальности
        </h3>
        <p>
            Мы
            можем
            время
            от
            времени
            изменять
            эту
            политику
            конфиденциальности.
            Мы
            будем
            публиковать
            любые
            изменения
            политики
            конфиденциальности
            на
            этой
            странице,
            и,
            если
            изменения
            будут
            значительными,
            мы
            предоставим
            более
            заметное
            уведомление,
            добавив
            уведомление
            на
            главные
            страницы
            Сервисов,
            экраны
            входа
            в
            систему
            или
            отправив
            вам
            уведомление
            по
            электронной
            почте.
            Мы
            рекомендуем
            вам
            просматривать
            нашу
            политику
            конфиденциальности
            всякий
            раз,
            когда
            вы
            используете
            Сервисы,
            чтобы
            быть
            в
            курсе
            наших
            методов
            работы
            с
            информацией
            и
            способов,
            которыми
            вы
            можете
            помочь
            защитить
            свою
            конфиденциальность.
        </p>
        <br>Если вы не согласны с какими-либо изменениями в этой политике конфиденциальности, вам потребуется прекратить
        использование Сервисов и деактивировать свои учетные записи, как указано выше.
        <h3>
            Свяжитесь
            с
            нами
        </h3>
        <p>
            Если
            у
            вас
            есть
            какие-либо
            вопросы
            относительно
            этой
            политики
            конфиденциальности,
            вы
            можете
            связаться
            с
            нами,
            используя
            приведенную
            ниже
            информацию.
        </p>
        <a href="https:slidelab.io">slidelab.io</a>
        <br>
        Электронная почта:
        <a href="mailto:info@slide-lab.ru">info@slide-lab.ru</a>
    </section>
</article>
`;
