export const PrivacyPolicy = `
<article class="policy"><h1>SlideLab Privacy Policy</h1>
    <div class="policy__date">Effective starting May 12, 2019</div>
    <section><h2>What this policy covers</h2>
        <p>Your privacy is important to us, and so is being transparent about how we collect, use, and share
            information about you. This policy is intended to help you understand:</p>
        <ul>
            <li>What information we collect about you</li>
            <li>How we use information we collect</li>
            <li>How we share information we collect</li>
            <li>How to access and control your information</li>
            <li>How we transfer information we collect internationally</li>
            <li>Other important privacy information</li>
        </ul>
        <p>This Privacy Policy covers the information we collect about you when you use our Services or
            otherwise interact with us (for example, via our support channels), unless a different policy is
            displayed.</p>
        <p>This policy also explains your choices about how we use information about you. Your choices include
            how you can object to certain uses of information about you and how you can access and update
            certain information about you.<b>If you do not agree with this policy, do not access or use our
                Services or interact with any other aspect of our business.</b></p>
        <p>SlideLab, we and us refers to Procreative, LLC, which controls the information SlideLab collects when
            you use the Services. We offer a range of services, including our web, desktop, and mobile products.
            We refer to all of these products, together with our other services and websites as "Services" in
            this policy.</p></section>
    <section><h2>What information we collect about you</h2>
        <p>We collect information about you when you provide it to us, when you use our Services, and when other
            sources provide it to us, as further described below.</p>
        <h3>Information you provide to us</h3>
        <p>We collect information about you when you input it into the Services or otherwise provide it directly
            to us.</p>
        <ul>
            <li><b>Account and Profile Information:</b> We collect information about you when you register for
                an account, create or modify your profile, set preferences, sign-up for or make purchases
                through the Services. For example, you provide your contact information and, in some cases,
                billing information when you register for the Services. You also have the option of adding
                details to your profile information to be displayed in our Services. We keep track of your
                preferences when you select settings within the Services.
            </li>
            <li><b>Content you provide through our products:</b> The Services include the SlideLab products you
                use, where we collect and store content that you post, send, receive and share. This content
                includes any information about you that you may choose to include.
                Content also includes the files and links you upload to the Services. Examples of content we
                collect and store include: the name of a presentation, background image or video uploads, any
                information you input into a presentation. We collect clickstream data about how you interact
                with and use features in the Services.
            </li>
            <li><b>Content you provide through our websites:</b> The Services also include our websites owned or
                operated by us. We collect other content that you submit to these websites, which include social
                media or social networking websites operated by us. For example, you provide content to us when
                you provide feedback or when you participate in any interactive features, surveys, contests,
                promotions, activities or events.
            </li>
            <li><b>Information you provide through our support channels:</b> The Services also include our
                customer support, where you may choose to submit information regarding a problem you are
                experiencing with a Service. Whether you designate yourself as an admin or billing contact, open
                a support ticket, speak to one of our representatives directly or otherwise engage with our
                support team, you will be asked to provide contact information, a summary of the problem you are
                experiencing, and any other documentation, screenshots or information that would be helpful in
                resolving the issue.
            </li>
            <li><b>Payment Information:</b> We collect certain payment and billing information when you register
                for certain paid Services. For example, we ask you to designate a billing representative,
                including name and contact information, upon registration. You might also provide payment
                information, such as payment card details, which we collect via secure payment processing
                services.
            </li>
        </ul>
    </section>
    <section><h2>Information we collect automatically when you use the Services</h2>
        <p>We collect information about you when you use our Services, including browsing our websites and
            taking certain actions within the Services.</p>
        <ul>
            <li><b>Your use of the Services:</b> We keep track of certain information about you when you visit
                and interact with any of our Services. This information includes the features you use; the links
                you click on and how you interact with others on the Services. We also collect information about
                the teams and people you work with and how you work with them, like who you collaborate with and
                communicate with most frequently.
            </li>
            <li><b>Device and Connection Information:</b> We collect information about the computer you use to
                access the Services. This device information includes your connection type and settings when you
                install, access, update, or use our Services. We also collect information through your device
                about your operating system, browser type, IP address, URLs of referring/exit pages, device
                identifiers, and crash data. We use your IP address and/or country preference in order to
                approximate your location to provide you with a better Service experience. How much of this
                information we collect depends on the type and settings of the device you use to access the
                Services.
            </li>
            <li><b>Cookies and Other Tracking Technologies:</b> SlideLab and our third-party partners, such as
                our advertising and analytics partners, use cookies and other tracking technologies (e.g., web
                beacons, device identifiers and pixels) to provide functionality and to recognize you across
                different Services and devices.
            </li>
        </ul>
    </section>
    <section><h2>Information we receive from other sources</h2>
        <p>We receive information about you from other Service users, from third-party services.</p>
        <ul>
            <li><b>Other users of the Services:</b> Other users of our Services may provide information about
                you when they submit content through the Services. For example, you may be mentioned in a
                support ticket opened by someone else. We also receive your email address from other Service
                users when they provide it in order to invite you to the Services. Similarly, an administrator
                may provide your contact information when they designate you as the billing or admin on your
                company's account.
            </li>
            <li><b>Other services you link to your account:</b> We receive information about you when you or
                your administrator integrate or link a third-party service with our Services. For example, if
                you create an account or log into the Services using your Google credentials, we receive your
                name and email address as permitted by your Google profile settings in order to authenticate
                you. You or your administrator may also integrate our Services with other services you use. The
                information we receive when you link or integrate our Services with a third-party service
                depends on the settings, permissions and privacy policy controlled by that third-party service.
                You should always check the privacy settings and notices in these third-party services to
                understand what data may be disclosed to us or shared with our Services.
            </li>
        </ul>
    </section>
    <section><h2>How we use information we collect</h2>
        <p>Below are the specific purposes for which we use the information we collect about you.</p>
        <ul>
            <li><b>To provide the Services and personalize your experience:</b> We use information about you to
                provide the Services to you, including to process transactions with you, authenticate you when
                you log in, provide customer support, and operate and maintain the Services. For example, we use
                the name and picture you provide in your account to identify you to other Service users. Our
                Services also include tailored features that personalize your experience, enhance your
                productivity, and improve your ability to collaborate effectively with others by automatically
                analyzing the activities of your team to provide activity feeds and notifications that are
                relevant for you and your team. We may use your email domain to infer your affiliation with a
                particular organization or industry to personalize the content and experience you receive on our
                websites. Where you use multiple Services, we combine information about you and your activities
                to provide an integrated experience, such as to allow you to find information from one Service
                while searching from another or to present relevant product information as you travel across our
                websites.
            </li>
            <li><b>For research and development:</b> We are always looking for ways to make our Services
                smarter, faster, secure, integrated, and useful to you. We use collective learnings about how
                people use our Services and feedback provided directly to us to troubleshoot and to identify
                trends, usage, activity patterns and areas for integration and improvement of the Services. We
                also test and analyze certain new features with some users before rolling the feature out to all
                users.
            </li>
            <li><b>To communicate with you about the Services:</b> We use your contact information to send
                transactional communications via email and within the Services, including confirming your
                purchases, reminding you of subscription expirations, responding to your comments, questions and
                requests, providing customer support, and sending you technical notices, updates, security
                alerts, and administrative messages. We also send you communications as you onboard to a
                particular Service to help you become more proficient in using that Service. These
                communications are part of the Services and in most cases you cannot opt out of them. If an opt
                out is available, you will find that option within the communication itself or in your account
                settings.
            </li>
            <li><b>To market, promote and drive engagement with the Services:</b> We use your contact
                information and information about how you use the Services to send promotional communications
                that may be of specific interest to you, including by email and by displaying SlideLab ads on
                other companies' websites and applications, as well as on platforms like Facebook and Google.
                These communications are aimed at driving engagement and maximizing what you get out of the
                Services, including information about new features, survey requests, newsletters, and events we
                think may be of interest to you. We also communicate with you about new product offers,
                promotions and contests. You can control whether you receive these communications as described
                below under "Opt-out of communications."
            </li>
            <li><b>For Customer support:</b> We use your information to resolve technical issues you encounter,
                to respond to your requests for assistance, to analyze crash information, and to repair and
                improve the Services.
            </li>
            <li><b>For safety and security:</b> We use information about you and your Service use to verify
                accounts and activity, to monitor suspicious or fraudulent activity and to identify violations
                of Service policies.
            </li>
            <li><b>To protect our legitimate business interests and legal rights:</b> Where required by law or
                where we believe it is necessary to protect our legal rights, interests and the interests of
                others, we use information about you in connection with legal claims, compliance, regulatory,
                and audit functions, and disclosures in connection with the acquisition, merger or sale of a
                business.
            </li>
            <li><b>With your consent:</b> We use information about you where you have given us consent to do so
                for a specific purpose not listed above. For example, we may publish testimonials or featured
                customer stories to promote the Services, with your permission.
            </li>
            <li><b>Legal bases for processing (for EEA users):</b> If you are an individual in the European
                Economic Area (EEA), we collect and process information about you only where we have legal bases
                for doing so under applicable EU laws. The legal bases depend on the Services you use and how
                you use them. This means we collect and use your information only where:
                <ul>
                    <li>We need it to provide you the Services, including to operate the Services, provide
                        customer support and personalized features and to protect the safety and security of the
                        Services;
                    </li>
                    <li>It satisfies a legitimate interest (which is not overridden by your data protection
                        interests), such as for research and development, to market and promote the Services and
                        to protect our legal rights and interests;
                    </li>
                    <li>You give us consent to do so for a specific purpose; or</li>
                    <li>We need to process your data to comply with a legal obligation.</li>
                </ul>
                If you have consented to our use of information about you for a specific purpose, you have the
                right to change your mind at any time, but this will not affect any processing that has already
                taken place. Where we are using your information because we or a third party (e.g. your
                employer) have a legitimate interest to do so, you have the right to object to that use though,
                in some cases, this may mean no longer using the Services.
            </li>
        </ul>
    </section>
    <section><h2>How we share information we collect</h2>
        <p>We are not in the business of selling information about you to advertisers or other third parties. We
            share information we collect about you in the ways discussed below.</p>
        <h3>Sharing with other Service users</h3>
        <p>When you use the Services, we share certain information about you with other Service users.</p>
        <ul>
            <li><b>For collaboration:</b> You can create content, which may contain information about you, and
                grant permission to others to see, share, edit, copy and download that content based on settings
                you or your administrator (if applicable) select.
            </li>
            <li><b>Managed accounts and administrators:</b> If you register or access the Services using an
                email address with a domain that is owned by your employer or organization, and such
                organization wishes to establish an account or site, certain information about you including
                your name, profile picture, contact info, content and past use of your account may become
                accessible to that organization’s administrator and other Service users sharing the same domain.
                If you are an administrator for a particular site or group of users within the Services, we may
                share your contact information with current or past Service users, for the purpose of
                facilitating Service-related requests.
            </li>
            <li><b>Community Forums:</b> Our websites offer publicly accessible blogs, forums, issue trackers,
                and wikis like SlideLab Community. You should be aware that any information you provide on these
                websites - including profile information associated with the account you use to post the
                information - may be read, collected, and used by any member of the public who accesses these
                websites. Your posts and certain profile information may remain even after you delete your
                account. We urge you to consider the sensitivity of any information you input into these
                Services. To request removal of your information from publicly accessible websites operated by
                us, please contact us as provided below. In some cases, we may not be able to remove your
                information, in which case we will let you know if we are unable to and why.
            </li>
        </ul>
        <h3>Sharing with third parties</h3>
        <p>We share information with third parties that help us operate, provide, improve, integrate, customize,
            support and market our Services.</p>
        <ul>
            <li><b>Service Providers:</b> We work with third-party service providers to provide website and
                application development, hosting, maintenance, backup, storage, virtual infrastructure, payment
                processing, analysis and other services for us, which may require them to access or use
                information about you. If a service provider needs to access information about you to perform
                services on our behalf, they do so under close instruction from us, including policies and
                procedures designed to protect your information.
            </li>
            <li><b>Third Party Apps:</b> You, your administrator or other Service users may choose to add new
                functionality or change the behavior of the Services by enabling integrations with third party
                apps within the Services. Doing so may give third-party apps access to your account and
                information about you like your name and email address, and any content you choose to use in
                connection with those apps.<br/> Third-party app policies and procedures are not controlled by
                us, and this privacy policy does not cover how third-party apps use your information. We
                encourage you to review the privacy policies of third parties before connecting to or using
                their applications or services to learn more about their privacy and information handling
                practices. If you object to information about you being shared with these third parties, please
                uninstall the app.
            </li>
            <li><b>Links to Third Party Sites:</b> The Services may include links that direct you to other
                websites or services whose privacy practices may differ from ours. If you submit information to
                any of those third party sites, your information is governed by their privacy policies, not this
                one. We encourage you to carefully read the privacy policy of any website you visit.
            </li>
            <li><b>Third-Party Widgets:</b> Some of our Services contain widgets and social media features, such
                as the Twitter "tweet" button. These widgets and features collect your IP address, which page
                you are visiting on the Services, and may set a cookie to enable the feature to function
                properly. Widgets and social media features are either hosted by a third party or hosted
                directly on our Services. Your interactions with these features are governed by the privacy
                policy of the company providing it.
            </li>
            <li><b>Compliance with Enforcement Requests and Applicable Laws; Enforcement of Our Rights:</b> In
                exceptional circumstances, we may share information about you with a third party if we believe
                that sharing is reasonably necessary to (a) comply with any applicable law, regulation, legal
                process or governmental request, including to meet national security requirements, (b) enforce
                our agreements, policies and terms of service, (c) protect the security or integrity of our
                products and services, (d) protect SlideLab, our customers or the public from harm or illegal
                activities, or (e) respond to an emergency which we believe in good faith requires us to
                disclose information to assist in preventing the death or serious bodily injury of any person.
            </li>
        </ul>
    </section>
    <section><h2>How we store and secure information we collect</h2>
        <h3>Information storage and security</h3>
        <p>We use data hosting service providers in the United States and host the information we collect, and
            we use technical measures to secure your data.</p><br/>While we implement safeguards designed to
        protect your information, no security system is impenetrable and due to the inherent nature of the
        Internet, we cannot guarantee that data, during transmission through the Internet or while stored on our
        systems or otherwise in our care, is absolutely safe from intrusion by others.
    </section>
    <section><h3>How long we keep information</h3>
        <p>How long we keep information we collect about you depends on the type of information, as described in
            further detail below. After such time, we will either delete or anonymize your information.</p>
        <ul>
            <li><b>Account information:</b> We retain your account information until you delete your account. We
                also retain some of your information as necessary to comply with our legal obligations, to
                resolve disputes, to enforce our agreements, to support business operations, and to continue to
                develop and improve our Services.
            </li>
            <li><b>Information you share on the Services:</b> If your account is deleted, some of your
                information and the content you have provided will remain in order to allow your team members or
                other users to make full use of the Services.
            </li>
            <li><b>Managed accounts:</b> If the Services are made available to you through an organization
                (e.g., your employer), we retain your information as long as required by the administrator of
                your account.
            </li>
            <li><b>Marketing information:</b> If you have elected to receive marketing emails from us, we retain
                information about your marketing preferences unless you specifically ask us to delete such
                information. We retain information derived from cookies and other tracking technologies for a
                reasonable period of time from the date such information was created.
            </li>
        </ul>
    </section>
    <section><h2>How to access and control your information</h2>
        <p>You have certain choices available to you when it comes to your information. Below is a summary of
            those choices, how to exercise them and any limitations. We will respond to requests about this
            within a reasonable timeframe.</p>
        <h3>Your Choices:</h3>
        <p>You have the right to request a copy of your information, to object to our use of your information
            (including for marketing purposes), to request the deletion or restriction of your information, or
            to request your information in a structured, electronic format. Below, we describe the tools and
            processes for making these requests. You may contact us as provided in the Contact Us section below
            to request assistance.</p><br/>Your request and choices may be limited in certain cases: for
        example, if fulfilling your request would reveal information about another person, or if you ask to
        delete information which we or your administrator are permitted by law or have compelling legitimate
        interests to keep. Where you have asked us to share data with third parties, for example, by installing
        third-party apps, you will need to contact those third-party service providers directly to have your
        information deleted or otherwise restricted. If you have unresolved concerns, you may have the right to
        complain to a data protection authority in the country where you live, where you work or where you feel
        your rights were infringed.
        <ul>
            <li><b>Access and update your information:</b> Our Services and related documentation give you the
                ability to access and update certain information about you from within the Service. You can
                update your profile information within your profile settings.
            </li>
            <li><b>Delete your account:</b> If you no longer wish to use our Services, you or your administrator
                may be able to delete your Services account. If you can delete your own account, that setting is
                available to you in your account settings. Otherwise, please contact your administrator. You may
                also contact SlideLab support (info@slide-lab.ru) to request your account be deleted.
            </li>
            <li><b>Delete your information:</b> Our Services give you the ability to delete certain information
                about you from within the Service. For example, you can remove certain profile information
                within your profile settings. Please note, however, that we may need to retain certain
                information for record keeping purposes, to complete transactions or to comply with our legal
                obligations.
            </li>
            <li><b>Request that we stop using your information:</b> In some cases, you may ask us to stop
                accessing, storing, using and otherwise processing your information where you believe we don't
                have the appropriate rights to do so. For example, if you believe a Services account was created
                for you without your permission or you are no longer an active user, you can request that we
                delete your account as provided in this policy. Where you gave us consent to use your
                information for a limited purpose, you can contact us to withdraw that consent, but this will
                not affect any processing that has already taken place at the time. You can also opt-out of our
                use of your information for marketing purposes by contacting us, as provided below. When you
                make such requests, we may need time to investigate and facilitate your request. If there is
                delay or dispute as to whether we have the right to continue using your information, we will
                restrict any further use of your information until the request is honored or the dispute is
                resolved, provided your administrator does not object (where applicable). If you object to
                information about you being shared with a third-party integration, please disable the
                integration or contact your administrator to do so.
            </li>
            <li><b>Opt out of communications:</b> You may opt out of receiving promotional communications from
                us by using the unsubscribe link within each email, or by contacting us as provided below to
                have your contact information removed from our promotional email list or registration database.
                Even after you opt out from receiving promotional messages from us, you will continue to receive
                transactional messages from us regarding our Services.
            </li>
            <li><b>Data portability:</b> Data portability is the ability to obtain some of your information in a
                format you can move from one service provider to another (for instance, when you transfer your
                mobile phone number to another carrier). Depending on the context, this applies to some of your
                information, but not to all of your information. Should you request it, we will provide you with
                an electronic file of your basic account information and the information you create on the
                spaces you under your sole control, like your personal SlideLab account.
            </li>
        </ul>
    </section>
    <section><h2>How we transfer information we collect internationally</h2>
        <h3>International transfers of information we collect</h3>
        <p>We collect information globally and primarily store that information in the United States. We
            transfer, process and store your information outside of your country of residence, to wherever we or
            our third-party service providers operate for the purpose of providing you the Services. Whenever we
            transfer your information, we take steps to protect it.</p>
        <ul>
            <li><b>International transfers within SlideLab Companies:</b> To facilitate our global operations,
                we transfer information to the United States and allow access to that information from countries
                in which SlideLab has operations for the purposes described in this policy. These countries may
                not have equivalent privacy and data protection laws to the laws of many of the countries where
                our customers and users are based.
            </li>
            <li><b>International transfers to third parties:</b> Some of the third parties described in this
                privacy policy, which provide services to us under contract, are based in other countries that
                may not have equivalent privacy and data protection laws to the country in which you reside.
            </li>
        </ul>
    </section>
    <section><h2>Other important privacy information</h2>
        <h3>Notice to End Users</h3>
        <p>Our Services are intended for both personal use and use by organizations. Where the Services are made
            available to you through an organization (e.g. your employer), that organization is the
            administrator of the Services and is responsible for the accounts and/or Service sites over which it
            has control. If this is the case, please direct your data privacy questions to your administrator,
            as your use of the Services is subject to that organization's policies. We are not responsible for
            the privacy or security practices of an administrator's organization, which may be different than
            this policy.</p><br/>Administrators are able to:
        <ul>
            <li>require you to reset your account password;</li>
            <li>restrict, suspend or terminate your access to the Services or your account;</li>
            <li>control your ability to edit, restrict, modify or delete account information;</li>
            <li>change your account information, including profile information or the email address associated
                with your account;
            </li>
            <li>access information in and about your account;</li>
            <li>access or retain information stored as part of your account;</li>
            <li>install or uninstall third-party integrations</li>
        </ul>
        <p>If you do not want an administrator to be able to assert control over your account or use of the
            Services, use your personal email address to register for or access the Services. If an
            administrator has not already asserted control over your account or access to the Services, you can
            update the email address associated with your account through your account settings in your profile.
            Once an administrator asserts control over your account or use of the Services, you will no longer
            be able to change the email address associated with your account without administrator approval.</p>
        <br/>Please contact your organization or refer to your administrator’s organizational policies for more
        information.<h3>Our policy towards children</h3>
        <p>The Services are not directed to individuals under 16. We do not knowingly collect personal
            information from children under 16. If we become aware that a child under 16 has provided us with
            personal information, we will take steps to delete such information. If you become aware that a
            child has provided us with personal information, please contact our support services.</p>
        <h3>Changes to our Privacy Policy</h3>
        <p>We may change this privacy policy from time to time. We will post any privacy policy changes on this
            page and, if the changes are significant, we will provide a more prominent notice by adding a notice
            on the Services homepages, login screens, or by sending you an email notification. We encourage you
            to review our privacy policy whenever you use the Services to stay informed about our information
            practices and the ways you can help protect your privacy.</p><br/>If you disagree with any changes
        to this privacy policy, you will need to stop using the Services and deactivate your account(s), as
        outlined above.<h3>Contact Us</h3>
        <p>If there are any questions regarding this privacy policy you may contact us using the information
            below.</p><a href="https://slidelab.io">slidelab.io</a><br/>Email:<a
                href="mailto:info@slide-lab.ru">info@slide-lab.ru</a></section>
</article>
`;
