<template>
    <div class="page text-page">
        <BaseHeader/>
        <div class="page__content" v-html="this.data"></div>
    </div>
</template>

<script>
/* eslint-disable */
import BaseHeader from '@/components/Layouts/BaseHeader';

export default {
    name: "Policy",
    components: { BaseHeader },
    data () {
        return {
            data: "",
        }
    },
    methods: {
        loadDataByLocale () {
            const { PrivacyPolicy } = require(`@/data/privacy-policy/${this.$i18n.locale}.js`);
            this.data = PrivacyPolicy;
        }
    },
    watch: {
        "$i18n.locale": () => {
            this.loadDataByLocale();
        }
    },
    mounted () {
        this.loadDataByLocale();
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/vars.scss';

.policy {
    font-family: 'Montserrat', sans-serif;

    &__date {
        font-size: 14px;
        opacity: .5;
        font-style: italic;
    }

    section {
        margin-bottom: 48px;
    }

    ul {
        position: relative;
        display: block;
        padding-top: 24px;
        padding-bottom: 24px;
        list-style-type: none;

        @media #{$mq-480} {
            padding-left: 24px;
        }

        li {
            position: relative;
            display: block;
            padding: 8px 0;

            &:before {
                content: '';
                position: absolute;
                display: block;
                top: 17px;
                left: -20px;
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background: $accent_color1;
            }

            ul li:before {
                opacity: .5;
            }
        }
    }
}
</style>
